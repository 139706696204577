import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./style.css";

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div class="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="Mortgage_Service shadow-lg p-3 rounded">
                <div class="Mortgage_Service_heading">
                  <h1 class="text-white">Property Rent / Sale Service</h1>
                </div>
                <img
                  class="img-fluid"
                  alt="rent-sale-service"
                  src="../images/rent-sale-service.jpg"
                />
                <br></br>
                <br></br>

                <p class="are_you_looking">
                  For property buyers, whether for investment or personal use,
                  Beyond Casa Real Estate is a trusted name. Our property
                  portfolio includes a wide range of options in some of Dubai's
                  most desired locations. Our RERA-qualified professional team
                  possesses comprehensive knowledge, conducts intensive
                  analysis, and maintains strong relationships with local
                  developers. This enables us to understand our clients'
                  preferences and make informed decisions.
                </p>
                <p class="are_you_looking">
                  Modern infra structure with world-class construction and
                  premium amenities and facilities Dubai where the luxury is
                  redefined attracts people around the globe to live a
                  comfortable and peaceful life.
                </p>
                <p class="are_you_looking">
                  Dubai's modern infrastructure, world-class construction, and
                  premium amenities have redefined luxury living, attracting
                  people from around the globe. At Beyond Casa Real Estate,
                  trust is non-negotiable, and we are committed to being one of
                  the most trustworthy brokerage firms in Dubai. We take our
                  role in guiding and assisting clients seriously, whether they
                  are seeking a small studio or a luxury villa.
                </p>
                <p class="are_you_looking">
                  The rental market in Dubai is expanding rapidly, offering
                  numerous options for individuals and large families. We strive
                  to excel in our industry, constantly providing our prestigious
                  clients with the best options that suit their desires and
                  needs.
                </p>
                <p class="are_you_looking">
                  When it comes to buying a property, Beyond Casa Real Estate
                  makes the tedious paperwork effortless with our extensive
                  knowledge. Our experience ensures you stay updated on current
                  market trends, guaranteeing your dream home and your
                  satisfaction.
                </p>
                {/* <p class="are_you_looking">
                  When buying a property, We Beyond casa DMCC with our sure
                  knowledge makes tedious and lengthy paper work effortless With
                  our experience we provide with latest updates on the current
                  market trends our experience, guarantees your dream home, and
                  your satisfaction.
                </p> */}

                {/* <p class="are_you_looking">
                  If you are in search of professional real estate consultants
                  to rent or sell your property, Evernest Real Estate’s
                  dedicated team of experts is the best in the industry.
                  Evernest has an exceptional team of expert real estate agents
                  that are ready to assist you in renting and/or selling your
                  properties. Our dynamic team of real estate professionals will
                  be there to provide you with the best advice about your
                  property, while sharing incredible in-house marketing services
                  with the most innovative Graphic Design expertise.{' '}
                </p> */}

                {/* <p class="are_you_looking">
                  Evernest Real Estate offers a variety of successful services
                  including Property Rent/Sale, Property Management, Mortgages,
                  Property Registration, Property Snagging, and Service
                  Maintenance.{' '}
                </p>

                <p class="are_you_looking">
                  With so many regulations and copious amounts of paperwork,
                  allow the professional knowledge that Evernest Real Estate
                  provides about current marketing and real estate trends to be
                  your guide. Evernest’s experience is here to help you
                  successfully buy, rent, manage, or sell your property. Your
                  success is our success, and our resourceful team will do
                  whatever it takes to guarantee your satisfaction. We are 100%
                  genuine and passionate about the services we offer and that is
                  proven in our results.{' '}
                </p>

                <p class="are_you_looking">
                  Reasons why you should let Evernest work for you...
                </p>
                <ul class="Mortgage_Service_ul">
                  <li> Our experienced Real Estate Team </li>
                  <li>
                    {' '}
                    Successful Property Rental, Sales and Purchase Transactions
                  </li>
                  <li> In-House Marketing </li>
                  <li> Graphic Design </li>
                  <li> Portal Advertising</li>
                  <li> 100% Satisfaction Guaranteed </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
