import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './mainView.css';
import { locationList } from '../../redux/slices/users';
import { useEffect } from 'react';

const SearchForm = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let publicUrl = process.env.PUBLIC_URL + '/';
  let imagealt = 'image';
  const { listOfLocation } = useSelector((state) => state.users);
  const [loc, setLoc] = useState([]);

  const [filterdValue, setFilterdValue] = useState({
    location: '',
    type: '',
    areaSize: '',
    fromBedSize: '',
    bedsize: '',
    toBedSize: '',
    fromPrice: '',
    toPrice: '',
    Price: ''
  });
  function handleChange(e) {
    let { name, value } = e.target;
    setFilterdValue({ ...filterdValue, [name]: value });
  }
  console.log(filterdValue);

  function handleSubmit(event) {
    event.preventDefault();
    history.push(
      `/filter-shop?location=${filterdValue.location}&type=${filterdValue.type}&areaSize=${filterdValue.areaSize}&bedsize=${filterdValue.bedsize}&price=${filterdValue.Price}`
    );
  }

  useEffect(() => {
    dispatch(locationList());
  }, []);

  useEffect(() => {
    if (listOfLocation) {
      setLoc(listOfLocation);
    }
  }, [listOfLocation]);

  return (
    <div
      className="ltn__car-dealer-form-area pb-115---"
      style={{ marginTop: '-100px', backgroundColor: '#f9f7f8' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="ltn__car-dealer-form-tab">
              <div
                className="tab-content tab-style-menu box-shadow-1 position-relative pb-10"
                
              >
                <div
                  className="tab-pane fade active show"
                  id="ltn__form_tab_1_1"
                >
                  <div className="car-dealer-form-inner">
                    <form
                      action="#"
                      className="ltn__car-dealer-form-box row"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div className="row">
                        {/* start ---- First Col  */}
                        <div
                          className="col-12 col-md-3 col-sm-12 text-select-panel"
                          style={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <div className="container">
                            <div className="row">
                              <div
                                className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- search-form-bed"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                {/* <div className="text-field">Location</div> */}
                                <select
                                  className="nice-select select"
                                  value={filterdValue.location}
                                  name="location"
                                  onChange={handleChange}
                                  style={{
                                    // background: '#297697',
                                    background: 'linear-gradient(to right,#297697, #226889)',
                                    backgroundColor: '#185373',
                                    border: 'transparent',
                                    color: '#ffffff',
                                    height: '31px'
                                  }}
                                >
                                  <option value="Select">Location</option>
                                  {loc.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                  {/* <option value="Select">Location</option>
                                  <option value="Kotagiri">Kotagiri</option>
                                  <option value="Coonoor">Coonoor</option>
                                  <option value="Ooty">Ooty</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end ---- First Col  */}

                        {/* start ---- second Col  */}
                        <div
                          className="col-12 col-md-2 col-sm-12 text-select-panel"
                          style={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          {/* From - to - Bed */}
                          <div className="container">
                            <div className="row">
                              <div
                                className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- search-form-bed"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                {/* <div className="text-field">Type</div> */}
                                <select
                                  className="nice-select select tab-menu-select text-white"
                                  value={filterdValue.type}
                                  name="type"
                                  onChange={handleChange}
                                  style={{
                                    // background: '#297697',
                                    background: 'linear-gradient(to right,#297697, #226889)',
                                    backgroundColor: '#185373',
                                    border: 'transparent',
                                    color: '#ffffff',
                                    height: '31px'
                                  }}
                                  
                                >
                                  <option value="Select">Type</option>
                                  <option value="2">RENT</option>
                                  <option value="1">BUY</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/* end ---- From- to - Bed */}
                        </div>
                        {/* end ---- second Col  */}

                        {/* start ---- third Col  */}
                        <div
                          className="col-12 col-md-2 col-sm-12 text-select-panel"
                          style={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          {/* From - to - Bed */}
                          <div className="container">
                            <div className="row">
                              <div
                                className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- search-form-bed"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                {/* <div className="text-field">Type</div> */}
                                <select
                                  className="nice-select select tab-menu-select text-white"
                                  value={filterdValue.bedsize}
                                  name="bedsize"
                                  onChange={handleChange}
                                  style={{
                                    // background: '#297697',
                                    background: 'linear-gradient(to right,#297697, #226889)',
                                    backgroundColor: '#185373',
                                    border: 'transparent',
                                    color: '#ffffff',
                                    height: '31px'
                                  }}
                                >
                                  <option value="Select">Beds</option>
                                  <option value="1">One Bedroom</option>
                                  <option value="2">Two Bedroom</option>
                                  <option value="3">Three Bedroom</option>
                                  <option value="4">Four Bedroom</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/* end ---- From- to - Bed */}
                        </div>
                        {/* end ---- third Col  */}

                        {/* start ---- fourth Col  */}
                        <div
                          className="col-12 col-md-2 col-sm-12 text-select-panel"
                          style={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          {/* From - to - Bed */}
                          <div className="container">
                            <div className="row">
                              <div
                                className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- search-form-bed"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                {/* <div className="text-field">Type</div> */}
                                <input
                                  className="nice-select select filter-price"
                                  value={filterdValue.Price}
                                  placeholder="Price"
                                  name="Price"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* end ---- From- to - Bed */}
                        </div>
                        {/* end ---- fourth Col  */}

                        {/* start ---- fifth Col  */}
                        <div
                          className="col-12 col-md-3 col-sm-12 tab-menu-find"
                         
                        >
                          {/* From - to - Bed */}
                          <div className="container">
                            <div className="row">
                              <div
                                className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- search-form-bed"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                {/* <div className="text-field">Type</div> */}
                                <div
                                  className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-md-12"
                                  
                                >
                                  <div
                                    className="btn-wrapper text-center mt-0 go-top"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end'
                                    }}
                                  >
                                    <Link
                                      className="btn theme-btn-1 text-uppercase search-find"
                                      onClick={handleSubmit}
                                    >
                                      Find Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end ---- From- to - Bed */}
                        </div>
                        {/* end ---- fifth Col  */}
                      </div>
                      {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-md-12">
                        <div className="btn-wrapper text-center mt-0 go-top">
                          <Link
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            onClick={handleSubmit}
                          >
                            Find Now
                          </Link>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
