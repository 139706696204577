import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.css';

class SponsorV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
      <div>
        <div className="ltn__brand-logo-area ltn__brand-logo-1 mt-80 pt-80--- pb-80 plr--9">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Our Partners
                  </h6>
                  <h1 className="section-title" style={{fontWeight: 'normal', fontSize: '18px'}}>
                    Exclusive offers directly from developers.
                  </h1>
                </div>
              </div>
            </div>
            <div className="row ltn__brand-logo-active">
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/1.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/2.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/3.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div>
              {/* <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/4.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/5.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img
                    src={publicUrl + 'assets/img/brand-logo/3.png'}
                    alt="Brand Logo"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        
        {/* <section class="blog_sections_relative mt-5">
          <div class="background_section_color"></div>
          <div class="container-fluid container_fluid">
            <div class="row">
              <div class="col-md-4 col-lg-3 col-12 w-25">
                <div class="features_data_services">
                  <h2>Latest Blog</h2>
                  <p>
                    Latest Property Updates, Real Estate Guide & Community News.
                  </p>
                </div>
              </div>
              <div class="col-md-8 col-12 col-lg-9">
                <div class="owl-slider">
                  <div id="carousel_blog_layout" class="owl-carousel">
                    <div class="item">
                      <a
                        // href="blogs/six-best-colfing-communities-to-live-in-dubai.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-1c4984f9536e7d655536c9c125bba5ae.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              6 Best Golfing Communities to Live Luxuriously in
                              Dubai for Golf Enthusiasts
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/five-dog-friendly-communities-for-pet-owners-in-dubai.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-4012957cc5a20168f76c8d6db0c8a377.jpg"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              Five Dog-Friendly Communities for Pet Owners in
                              Dubai
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/the-ultimate-guide-to-invest-in-dubai.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-128faa1542ceb3ae1e76475935e3395c.jpg"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              The Ultimate Guide to Dubai Investment - From
                              Anywhere in the World
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/what-makes-dubai-a-place-to-call-home.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-67a7129911a207adf4e48d5afbc3496d.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              What Makes Tourist Destination Dubai A Place to
                              Call Home?
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/top-seven-best-schools-in-dubai.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-e90ea7e3caa7b3847bc2c41b85f10083.jpg"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              List of Top 7 Best Schools in Dubai
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/everything-about-golden-visa-in-uae.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-ce055b016cc97fa41c0374eb109f9d82.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              Everything about Golden Visa that You Should Know
                              in the UAE
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/best-places-to-visit-in-dubai.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-ffa34a116b45e0e62e1dabde46efc15d.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              Best Places to Visit in Dubai - An Extraordinary
                              Trip Guide
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/cost-of-living-in-dubai-for-expats.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-35d911d073d49b1f02c0ec5d38dc747c.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              What Cost of Living Contributes in Dubai for
                              Expats in 2022?
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="item">
                      <a
                        // href="blogs/best-real-estate-company-in-dubai-2022.html"
                        href='#'
                        class="blog_achanker"
                      >
                        <img
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                          }}
                          src="/images/blog/m-6d739205dddfe706146061ee2dd384a0.webp"
                          alt="Blog Images"
                          class="Blogowlcarouselimg"
                        />
                        <div class="card">
                          <div class="card-body card_body">
                            <h6
                              style={{ fontSize: '16px', color: '#000' }}
                              class="card-title"
                            >
                              Best Real Estate Company in Dubai 2022
                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export default SponsorV2;
