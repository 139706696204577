import React from 'react';
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v3';
import SearchForm from './section-components/search-form';
import Features from './section-components/features-v1';
import ProductSlider from './section-components/product-slider-v3';
import FeaturedItem from './section-components/featured-item-v1';
import Discover from './section-components/discover';
import Video from './section-components/video-v1';
import Testimonial from './section-components/testimonial-v1';
import Sponsor from './section-components/sponsor-v2';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import AboutV4 from './section-components/about-v4Home';
import Footer from './global-components/footer';
import VideoV4 from './section-components/video-v4';



const Home_V3 = () => {
  window.addEventListener(
    'load',
    function () {
      // alert('hello!');
      document.querySelector('video');
    },
    false
  );

  return (
    <div>
      <Navbar />
      {/* <Banner /> */}

      {<VideoV4 />}
      <SearchForm />
      {/* <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---"/> */}
      {/* <ProductSlider /> */}
      <Discover />
      <FeaturedItem />
      {/* <Video /> */}

      {/* <BlogSlider sectionClass="pt-120"/> */}
      {/* <CallToActionV1 /> */}
      <AboutV4 />
      {/* <Testimonial /> */}
      <Sponsor />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V3;
