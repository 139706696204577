import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";

class NavbarV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    var prodcutDetailsLocation = window.location.pathname;
    var currentPage =
      window.location.pathname === "/about" ||
      window.location.pathname === "/contact" ||
      window.location.pathname === "/propertyMortgage" ||
      window.location.pathname === "/propertyManagement" ||
      window.location.pathname === "/serviceMaintenance" ||
      window.location.pathname === "/propertySnagging" ||
      window.location.pathname === "/propertyRentSale" ||
      window.location.pathname === "/propertyRegistration" ||
      window.location.pathname === "/buyResidential" ||
      window.location.pathname === "/buyCommercial" ||
      window.location.pathname === "/rentResidential" ||
      window.location.pathname === "/rentCommercial" ||
      window.location.pathname === "/filter-shop" ||
      prodcutDetailsLocation.includes("a");
    console.log(currentPage);
    return (
      <div>
        <header
          className={`ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-bgcolor gradient-color-4--- ${
            currentPage ? "ltn__header-bgcolor-new" : ""
          }`}
        >
          {/* <div className="ltn__header-top-area top-area-color-white">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you">
                          <i className="icon-mail" /> info@webmail.com
                        </a>
                      </li>
                      <li>
                        <a href="locations.html">
                          <i className="icon-placeholder" /> 15/A, Nest Tower,
                          NYC
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="top-bar-right text-end">
                    <div className="ltn__top-bar-menu">
                      <ul>
                        <li>
                        //   ltn__language-menu
                          <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                            <ul>
                              <li>
                                <Link to="#" className="dropdown-toggle">
                                  <span className="active-currency">
                                    English
                                  </span>
                                </Link>
                                <ul>
                                  <li>
                                    <Link to="#">Arabic</Link>
                                  </li>
                                  <li>
                                    <Link to="#">Bengali</Link>
                                  </li>
                                  <li>
                                    <Link to="#">Chinese</Link>
                                  </li>
                                  <li>
                                    <Link to="#">English</Link>
                                  </li>
                                  <li>
                                    <Link to="#">French</Link>
                                  </li>
                                  <li>
                                    <Link to="#">Hindi</Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <Social />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
	</div> */}
          <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo go-top">
                      <a href="/">
                        <img
                          src={publicUrl + "assets/img/logo192-small.png"}
                          alt="Logo"
                          style={{ width: "150px" }}
                        />
                        <h3
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            marginBottom: "0px",
                          }}
                        >
                          {/* Beyondcasa */}
                        </h3>
                      </a>
                    </div>
                    <div className="get-support clearfix d-none">
                      <div className="get-support-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="get-support-info">
                        <h6>Get Support</h6>
                        <h4>
                          <a href="tel:+123456789">123-456-789-10</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column menu-color-white">
                  <div className="header-menu d-none d-xl-block go-top">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li className="menu-icon">
                            <a href={`/`}>
                              <i
                                className="fa-solid fa-house"
                                style={{ fontSize: "14px" }}
                              />
                              Home
                            </a>
                            {/* <ul className="sub-menu menu-pages-img-show">
                              <li>
                                <Link to="/">Home Style 01</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-1.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v2">Home Style 02</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-2.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v3">Home Style 03</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-3.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v4">Home Style 04</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-4.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v5">
                                  Home Style 05{" "}
                                  <span className="menu-item-badge">video</span>
                                </Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-5.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v6">Home Style 06</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-6.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v7">Home Style 07</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-7.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v8">Home Style 08</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-8.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v9">Home Style 09</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-9.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                              <li>
                                <Link to="/home-v10">Home Style 10</Link>
                                <img
                                  src={
                                    publicUrl +
                                    "assets/img/home-demos/home-11.jpg"
                                  }
                                  alt="#"
                                />
                              </li>
                            </ul> */}
                          </li>
                          {/* <li className="menu-icon">
                            <a href={`/filter-shop?type=`}>
                              <i
                                className="fa-solid fa-chart-area"
                                style={{ fontSize: '14px' }}
                              />
                              Properties
                            </a> */}
                          {/* <ul>
                              <li>
                                <Link to="/about">About</Link>
                              </li>
                              <li>
                                <Link to="/service">Services</Link>
                              </li>
                              <li>
                                <Link to="/service-details">
                                  Service Details
                                </Link>
                              </li>
                              <li>
                                <Link to="/portfolio">Portfolio</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-v2">Portfolio - 02</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-details">
                                  Portfolio Details
                                </Link>
                              </li>
                              <li>
                                <Link to="/team">Team</Link>
                              </li>
                              <li>
                                <Link to="/team-details">Team Details</Link>
                              </li>
                              <li>
                                <Link to="/faq">FAQ</Link>
                              </li>
                              <li>
                                <Link to="/location">Google Map Locations</Link>
                              </li>
                            </ul> */}
                          {/* </li> */}

                          <li className="menu-icon">
                            {/* <a href={`/filter-shop?type=3`}>
                              <i className="fa-solid fa-hotel" style={{fontSize:"13px"}}/>
                              Resorts
                            </a> */}
                            {/* <ul>
                              <li>
                                <Link to="/about">About</Link>
                              </li>
                              <li>
                                <Link to="/service">Services</Link>
                              </li>
                              <li>
                                <Link to="/service-details">
                                  Service Details
                                </Link>
                              </li>
                              <li>
                                <Link to="/portfolio">Portfolio</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-v2">Portfolio - 02</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-details">
                                  Portfolio Details
                                </Link>
                              </li>
                              <li>
                                <Link to="/team">Team</Link>
                              </li>
                              <li>
                                <Link to="/team-details">Team Details</Link>
                              </li>
                              <li>
                                <Link to="/faq">FAQ</Link>
                              </li>
                              <li>
                                <Link to="/location">Google Map Locations</Link>
                              </li>
                            </ul> */}
                          </li>
                          <li className="menu-icon">
                            <Link to={`/about`}>
                              <i
                                className="fa-solid fa-address-card"
                                style={{ fontSize: "13px" }}
                              />
                              About
                            </Link>
                            {/* <ul>
                              <li>
                                <Link to="/shop">Shop</Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Shop Grid</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Shop Left sidebar
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Shop Right sidebar
                                </Link>
                              </li>
                              <li>
                                <Link to="/product-details">Shop Details</Link>
                              </li>
                              <li>
                                <Link to="/cart">Cart</Link>
                              </li>
                              <li>
                                <Link to="/checkout">Checkout</Link>
                              </li>
                              <li>
                                <Link to="/my-account">My Account</Link>
                              </li>
                              <li>
                                <Link to="/login">Sign in</Link>
                              </li>
                              <li>
                                <Link to="/register">Register</Link>
                              </li>
                            </ul> */}
                          </li>

                          <li className="menu-icon">
                            <a href="">
                              <i
                                className="fa-solid fa-angle-down"
                                style={{ fontSize: "13px" }}
                              />
                              Buy
                            </a>
                            <ul>
                              <li>
                                <Link to="/buyResidential?type=1&subType=1">
                                  Residential
                                </Link>
                              </li>
                              <li>
                                <Link to="/buyCommercial?type=1&subType=2">
                                  Commercial
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-icon">
                            <a href="">
                              <i
                                className="fa-solid fa-angle-down"
                                style={{ fontSize: "13px" }}
                              />
                              Rent
                            </a>
                            <ul>
                              <li>
                                <Link to="/rentResidential?type=2&subType=1">
                                  Residential
                                </Link>
                              </li>
                              <li>
                                <Link to="/rentCommercial?type=2&subType=2">
                                  Commercial
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-icon">
                            <a href="">
                              <i
                                className="fa-solid fa-angle-down"
                                style={{ fontSize: "13px" }}
                              />
                              Service
                            </a>
                            <ul>
                              <li>
                                <Link to="/propertyMortgage">
                                  Property Mortgage
                                </Link>
                              </li>
                              <li>
                                <Link to="/propertyManagement">
                                  Property Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/serviceMaintenance">
                                  Property Maintenance
                                </Link>
                              </li>
                              <li>
                                <Link to="/propertyRentSale">
                                  Property Rent / Sale
                                </Link>
                              </li>
                              <li>
                                <Link to="propertySnagging">
                                  Property Snagging
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-icon">
                            <Link to={`/contact`}>
                              <i
                                className="fa-solid fa-address-book"
                                style={{ fontSize: "13px" }}
                              />
                              Contact us
                            </Link>
                            {/* <ul>
                              <li>
                                <Link to="/blog">News</Link>
                              </li>
                              <li>
                                <Link to="/blog-grid">News Grid</Link>
                              </li>
                              <li>
                                <Link to="/blog-left-sidebar">
                                  News Left sidebar
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-right-sidebar">
                                  News Right sidebar
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-details">News details</Link>
                              </li>
                            </ul> */}
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col--- ltn__header-options ltn__header-options-2 ">
                  {/* Mobile Menu Button */}
                  <div className="mobile-menu-toggle d-xl-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo1">
                <Link to="/">
                  <img
                    src={publicUrl + "assets/img/logo192-small.png"}
                    alt="Logo"
                    style={{ width: "100px" }}
                  />
                </Link>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>
            {/* <div className="ltn__utilize-menu-search-form">
              <form action={"#"}>
                <input type="text" placeholder="Search..." />
                <button>
                  <i className="fas fa-search" />
                </button>
              </form>
            </div> */}
            <div className="ltn__utilize-menu">
              <ul>
                {/* <li style={{alignItems:"center",dispaly:"flex"}}>
                  <i className="fa-solid fa-house" style={{fontSize:"18px"}}/>
                  <a href= {`/filter-shop?type=1`} >Guest Houses</a>
                  <ul className="sub-menu">
                    {/* <li>
                      <Link to="/">Home Style 01</Link>
                    </li> */}
                {/* <li>
                      <Link to="/home-v2">Home Style 02</Link>
                    </li>
                    <li>
                      <Link to="/home-v3">Home Style 03</Link>
                    </li>
                    <li>
                      <Link to="/home-v4">Home Style 04</Link>
                    </li>
                    <li>
                      <Link to="/home-v5">
                        Home Style 05{" "}
                        <span className="menu-item-badge">video</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-v6">Home Style 06</Link>
                    </li>
                    <li>
                      <Link to="/home-v7">Home Style 07</Link>
                    </li>
                    <li>
                      <Link to="/home-v8">Home Style 08</Link>
                    </li>
                    <li>
                      <Link to="/home-v9">Home Style 09</Link>
                    </li>
                    <li>
                      <Link to="/home-v10">Home Style 10</Link>
                    </li> 
                  </ul>
                </li> */}
                <li className="mobile-navbar">
                  <i
                    className="fa-solid fa-house"
                    style={{ fontSize: "19px" }}
                  />
                  <Link to="/">HOME</Link>
                </li>

                {/* <li className="mobile-navbar">
                  <i className="fa-solid fa-hotel" style={{fontSize:"18px"}}/>
                  <Link to="/filter-shop?type=3">Resorts</Link>
                </li> */}

                <li className="mobile-navbar">
                  <i
                    className="fa-solid fa-address-card"
                    style={{ fontSize: "18px" }}
                  />
                  <Link to="/about">ABOUT</Link>
                </li>

                <li>
                  <span>
                    <i
                      className="fa-solid fa-address-book"
                      style={{ fontSize: "18px" }}
                    />
                    <Link to="#">BUY</Link>
                  </span>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/buyResidential?type=1&subType=1">
                        Residential
                      </Link>
                    </li>
                    <li>
                      <Link to="/buyCommercial?type=1&subType=2">
                        Commercial
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li className="mobile-navbar"> */}
                <li>
                  <span>
                    <i
                      className="fa-solid fa-address-book"
                      style={{ fontSize: "18px" }}
                    />
                    <Link to="#">RENT</Link>
                  </span>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/rentResidential?type=2&subType=1">
                        Residential
                      </Link>
                    </li>
                    <li>
                      <Link to="/rentCommercial?type=2&subType=2">
                        Commercial
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <span>
                    <i
                      className="fa-solid fa-address-book"
                      style={{ fontSize: "18px" }}
                    />
                    <Link to="#">SERVICES</Link>
                  </span>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/propertyRentSale">
                      Property Rent / Sale
                      </Link>
                    </li>
                    <li>
                      <Link to="/propertyManagement">
                      Property Management
                      </Link>
                    </li>
                    <li>
                      <Link to="/serviceMaintenance">
                      Service Maintenance
                      </Link>
                    </li>
                    <li>
                      <Link to="/propertyMortgage">
                      Property Mortgages
                      </Link>
                    </li>
                    <li>
                      <Link to="/propertySnagging">
                      Property Snagging
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="mobile-navbar">
                  <i
                    className="fa-solid fa-address-book"
                    style={{ fontSize: "18px" }}
                  />
                  <Link to="/contact">Contact us</Link>
                </li>

                {/* <li>
                  <Link to="/about">About</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Service Details</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-v2">Portfolio - 02</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/team-details">Team Details</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/location">Google Map Locations</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/shop">Shop</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link to="/shop-grid">Shop Grid</Link>
                    </li>
                    <li>
                      <Link to="/shop-left-sidebar">Shop Left sidebar</Link>
                    </li>
                    <li>
                      <Link to="/shop-right-sidebar">Shop Right sidebar</Link>
                    </li>
                    <li>
                      <Link to="/product-details">Shop Details</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/checkout">Checkout</Link>
                    </li>
                    <li>
                      <Link to="/my-account">My Account</Link>
                    </li>
                    <li>
                      <Link to="/login">Sign in</Link>
                    </li>
                    <li>
                      <Link to="/register">Register</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/blog-grid">News</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/blog">News</Link>
                    </li>
                    <li>
                      <Link to="/blog-grid">News Grid</Link>
                    </li>
                    <li>
                      <Link to="/blog-left-sidebar">News Left sidebar</Link>
                    </li>
                    <li>
                      <Link to="/blog-right-sidebar">News Right sidebar</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">News details</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Service Details</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-2">Portfolio - 02</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/team-details">Team Details</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/history">History</Link>
                    </li>
                    <li>
                      <Link to="/add-listing">Add Listing</Link>
                    </li>
                    <li>
                      <Link to="/locations">Google Map Locations</Link>
                    </li>
                    <li>
                      <Link to="/404">404</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/coming-soon">Coming Soon</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li> */}
              </ul>
            </div>
            {/* <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <Link to="/my-account" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user" />
                    </span>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to="/wishlist" title="Wishlist">
                    <span className="utilize-btn-icon">
                      <i className="far fa-heart" />
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </Link>
                </li>
                <li>
                  <Link to="/cart" title="Shoping Cart">
                    <span className="utilize-btn-icon">
                      <i className="fas fa-shopping-cart" />
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </Link>
                </li>
              </ul>
            </div> */}
            <div className="ltn__social-media-2">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100079551911287"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Beyondcasa_prop" title="Twitter">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/Beyondcasa_property/"
                    title="Instagram"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <a
          href="https://wa.me/+971551577660"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      </div>
    );
  }
}

export default NavbarV3;
