import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.css';

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div class="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px',
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                 
                </div>{' '}
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="Mortgage_Service shadow-lg p-3 rounded">
                <div class="Mortgage_Service_heading">
                  <h1 class="text-white">
                    {' '}
                    Property Registration Service in UAE
                  </h1>
                </div>
                <img
                  class="img-fluid"
                  alt="registration-servoce"
                  src="../images/service-registration.jpg"
                />
                <br></br>
                <br></br>
                <p class="are_you_looking">
                  Property Registration can often be a challenging process for
                  many property owners. With the profuse amount of paperwork,
                  changing laws, and regulations, our real estate professionals
                  are knowledgeable about the current processes and make it easy
                  for you by managing all of the necessary documentation
                  correctly.{' '}
                </p>

                <p class="If_you_require">
                  If you need assistance with any of the following, contact us
                  today!{' '}
                </p>

                <ul class="Mortgage_Service_ul">
                  <li> Visa Applications</li>
                  <li> Ownership Transference of a Property</li>
                  <li> Property Registration</li>
                  <li> Title Deeds</li>
                  <li> Property Gifting</li>
                  <li> Power of Attorney</li>
                </ul>

                <p class="If_you_require">
                  Visa applications, property gifting, title deeds, and
                  transferring property ownership can be time-consuming and
                  daunting…
                </p>

                <p class="If_you_require">
                  So, why not share the responsibility with a professional team
                  of real estate experts that know the process inside and out?{' '}
                </p>

                <p class="If_you_require">
                  Let the responsible real estate consultants at Evernest guide
                  you in your property’s registration process so that no details
                  fall through the cracks and you get the best deal possible for
                  the best possible price! The expert care and knowledge we have
                  is necessary for successful real estate ventures.{' '}
                </p>

                <p class="If_you_require">
                  Failure to register properties correctly can result in delays
                  and high legal risks that can become costly if not done the
                  right way the first time around. The Evernest Real Estate team
                  is attentive to details and understands the particular
                  processes required to accurately and effectively manage the
                  property registration procedures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
