import { map } from 'lodash';
// import axios from 'axios';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  userList: [],
  propByFilter: [],
  propById: [],
  booking: []
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    getPropByFilterSuccess(state, action) {
      state.isLoading = false;
      state.propByFilter = action.payload;
    },
    getPropByIdSuccess(state, action) {
      state.isLoading = false;
      state.propById = action.payload;
    },
    updateByBooking(state, action) {
      state.isLoading = false;
      state.booking = action.payload;
    },
    locationListSuccess(state, action) {
      state.isLoading = false;
      state.listOfLocation = action.payload;
    },
    imageListSuccess(state, action) {
      state.isLoading = false;
      state.listOfImages = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function setIpSession() {
  var _ip = 'https://beyondcasa.ae:4001';
  localStorage.setItem('ip', _ip);
}
setIpSession();

// Actions
// export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getUserList(values) {
  const postData = {
    isNameVerify: values
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://beyondcasa.ae:4001/api/listProperties',
        postData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPropByFilter(
  location,
  type,
  sub_type,
  bedsize,
  price,
  priority
) {
  // console.log(type, sub_type, 'user');
  // console.log(
  //   typeof type,
  //   typeof location,
  //   typeof bedsize,
  //   typeof price,
  //   priority,
  //   'user11'
  // );
  var obj = {};
  var postData = {};
  if (location && type) {
    postData = { location: location, type: type };
  } else if (location) {
    postData = { location: location };
  } else if ((location === '', type === '', priority)) {
    postData = { priority: priority };
  } else if (type && sub_type) {
    postData = { type: type, sub_type: sub_type };
  }

  if (type) {
    obj.type = type;
  }
  if (sub_type) {
    obj.sub_type = sub_type;
  }
  if (location) {
    obj.location = location;
  }
  if (bedsize) {
    obj.bedsize = bedsize;
  }
  if (price) {
    obj.price = price;
  }

  console.log(obj);

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://beyondcasa.ae:4001/api/listPropertiesBySearch',
        obj,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      // console.log(response);
      dispatch(slice.actions.getPropByFilterSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPropById(id) {
  const postData = { id };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://beyondcasa.ae:4001/api/viewProperties',
        postData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      dispatch(slice.actions.getPropByIdSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createBookingAll(
  name,
  email,
  comment,
  _id,
  serviceType,
  phone
) {
  const postData = {
    name: name,
    email: email,
    message: comment,
    property_id: _id,
    serviceType: serviceType,
    //phone: "898989",
    mobile: phone
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://beyondcasa.ae:4001/api/createContact',

        postData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      dispatch(slice.actions.updateByBooking(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// location list
export function locationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://beyondcasa.ae:4001/api/locationList'
      );

      dispatch(slice.actions.locationListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// // Images list
// export function imageList() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         'http://192.168.1.14:3000/image/list'
//       );

//       dispatch(slice.actions.imageListSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------
