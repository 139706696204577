import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { createBookingAll } from "../../redux/slices/users";

const StatusMsg = () => {
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", color: "#ffffff" }}>
          Sent Successfully
        </h3>
      </div>
    </div>
  );
};

const AboutV4 = () => {
  const dispatch = useDispatch();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { booking } = useSelector((state) => state.users);
  const [createBooking, setCreateBooking] = useState({
    name: "",
    email: "homepage1@gmail.com",
    comment: "",
    property_id: "",
    serviceType: "",
    phone: "",
  });
  const [validationFiled, setValidationFiled] = useState(false);

  const handleInput = (e) => {
    let { name, value } = e.target;
    setCreateBooking({ ...createBooking, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (createBooking.name !== "" && createBooking.phone !== "") {
      dispatch(
        createBookingAll(
          createBooking.name,
          createBooking.email,
          createBooking.comment,
          createBooking.property_id,
          createBooking.serviceType,
          createBooking.phone
        )
      );
    } else {
      setValidationFiled(true);
    }
  };

  return (
    <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top bg-aboutGr">
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <div id="contact-form-left">
              <div className="row">
                <div className="text_design">
                  <h5> Why Beyond Casa Real Estate Property.</h5>
                  <h3>IS THE PERFECT CHOICE?</h3>
                </div>
                <div className="text_design">
                  {/* <h5> Local Knowledge</h5> */}

                  <p>
                    Beyond Casa Real Estate brokers is a well-experienced and
                    well-respected name in the field of real estate brokerage.
                    we are registered brokerage firm with rera operating under
                    the ORN number 35926 and BRN 60751
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div>
              <form id="contact-form">
                <div className="row">
                  {booking.status === 1 ? (
                    <StatusMsg />
                  ) : (
                    <div>
                      <div className="text_design">
                        <h3>INQUIRE NOW</h3>
                        <h5>Contact with us to find a perfect property.</h5>
                      </div>
                      <div className="col-md-12">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            placeholder="Enter your name"
                            name="name"
                            required
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            required
                            onChange={handleInput}
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-item input-item-textarea ltn__custom-icon">
                          <textarea
                            name="comment"
                            onChange={handleInput}
                            placeholder="Enter message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="btn-wrapper mt-0">
                          <button
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="btn-wrapper mt-0">
                          <p
                            style={{
                              color: "red",
                              display: validationFiled ? "block" : "none",
                            }}
                          >
                            Please fill all the field
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV4;
