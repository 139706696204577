import React, { Component } from "react";
import Slider from "react-slick";
import "../section-components/mainView.css";

export default class SliderTesting extends Component {
    constructor(props) {
      super(props);
      this.state = {
        nav1: null,
        nav2: null
      };
    }

    componentDidMount() {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
  
    render() {

      console.log(this.props);
      let domainUrl = localStorage.getItem("ip");
      // setTimeout(() => {
      //   var a = document.getElementsByClassName('slick-arrow slick-next')[0].innerHTML = ''
      //   console.log(a)
      // },7000)
     

      function PrevArrow(){
      //  return <button type='button' class='slick-prev'><i class="fas fa-angle-double-left"></i></button>
      }

      function NextArrow(){
        // return <button type='button' class='slick-next'><i class="fas fa-angle-double-right"></i></button>
      }

      return (
        <div className="ltn__img-slider-area mb-90">
            <div className="container-fluid slick-arrow-1 slick-arrow-1-inne">
             {/* <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all"></div> */}
            
            <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                // autoplay={true}
                arrows={true}
                // focusOnSelect= {true}
                // draggable = {true}
                // prevArrow = {<PrevArrow />}
                // nextArrow ={<NextArrow />}
                
            >
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.galleryImages[0]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[0]}`} alt="image" className="carousel-main" />
                </a>
                {/* <span className="properties-padge" >
                  BUY
                </span> */}
            </div>
            
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.galleryImages[1]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[1]}`} alt="image" className="carousel-main" />
                </a>
                {/* <span className="properties-padge" >
                  BUY
                </span> */}
            </div>
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.galleryImages[2]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[2]}`} alt="image" className="carousel-main" />
                </a>
                {/* <span className="properties-padge" >
                  BUY
                </span> */}
            </div>
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.galleryImages[3]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[3]}`} alt="image" className="carousel-main"/>
                </a>
                {/* <span className="properties-padge" >
                  BUY
                </span> */}
            </div>
            {/* <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div> */}
          </Slider>
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            // autoplay={true}
            arrows={true}
            // draggable = {true}
            // prevArrow = {<PrevArrow />}
            // nextArrow ={<NextArrow />}
            
          >

                <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.carouselImages[0]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[0]}`} alt="image" className="carousel-small"  />
                </a>
            </div>
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.carouselImages[1]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[1]}`} alt="image" className="carousel-small"/>
                </a>
            </div>
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.carouselImages[2]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[2]}`} alt="image" className="carousel-small"/>
                </a>
            </div>
            <div className="col-lg-12">
                <a
                    // href={`${domainUrl}/${this.props.carouselImages[3]}`}
                    data-rel="lightcase:myCollection"
                >
                    <img src={`${domainUrl}/${this.props.carouselImages[3]}`} alt="mage" className="carousel-small"/>
                </a>
            </div>

            {/* <div>
              <h3>1</h3>
            </div>
            <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div> */}
          </Slider>
          </div>
        </div>
      );
    }
  }

// export default SliderTesting;