import React, { Component, useEffect, useState } from "react";
import Radium from "radium";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getUserList, getPropByFilter } from "../../redux/slices/users";
import Slider from "react-slick";
import "./featuredClass.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "./style.css";
import $ from "jquery";

const style = {
  color: "#000000",
  ":hover": {
    color: "#ffffff",
  },
};

const FeaturedItemV1 = () => {
  const [loader, setLoader] = useState(true);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let domainUrl = localStorage.getItem("ip");
  const dispatch = useDispatch();
  const { propByFilter } = useSelector((state) => state.users);
  const [feature, setFeature] = useState([
    {
      _id: "632567319a24947bff0c5d57",
      type: 1,
      sub_type: 1,
      title: "Apartment for Rent in Bayz by Danube Business Bay",
      code: "1838-Ap-R-0803",
      price: "50,000 / Year",
      amenities: [
        "Gym",
        "Basketball court",
        "Garage Attached",
        "Air conditioning",
        "Heating",
        "Electricity",
        "Natural Gas",
        "Elevator",
        "Smoke detectors",
        "WiFi",
      ],
      gallery: [],
      carousel_images: [
        "images/carousel/1663395624849.jpg",
        "images/carousel/1663395624955.jpg",
        "images/carousel/1663395624999.jpg",
        "images/carousel/1663395625000.jpg",
      ],
      images: "images/images/1663395620757.jpg",
      subject_image: "",
      year_build: 2020,
      lot_area: 1111,
      subject: [],
      floor_plan: [
        {
          title: "Floor1",
          description: "Floor1Des",
          image: "images/floor_images/1663395608885.jpg",
        },
        { title: "Floor2", description: "Floor2Des", image: "" },
      ],
      video_link: "",
      description:
        "<p>Description</p><p>Evernest Real Estate is excited to showcase a studio apartment for rent in Bayz by Danube. It is an ideal space for residents who wants to be connected to the city! Living in style, at a sophisticated address in Dubai.&nbsp;</p><p>Dwelling in Bayz by the Danube is making sure you gaze at the breathtaking views of the Burj Khalifa from the comfort of your home.&nbsp;The project redefines luxury in a unique and fashionable way, ideal for those who wish to live in style. You have enough space for gatherings and relaxation since each unit is spacious, airy, and well-built.&nbsp;</p><h4>Property Features:&nbsp;</h4><ul><li>A swimming pool</li><li>Built-in wardrobes</li><li>Kitchen Appliances</li><li>View of Landmark</li><li>Covered Parking</li><li>Shared Spa</li><li>Gym&nbsp;</li><li>Barbecue deck</li><li>Fully Fitted Kitchen</li></ul><p>Each apartment features a living room that can be converted into a bedroom, giving you more privacy than ever. The interiors of these affluent homes are really fascinating, while the tower dominates Dubai's most prestigious neighborhood, Business Bay. Additionally, because they are furnished with elegant materials, you feel welcomed and excited the moment you step into your home.</p>",
      door_no: "DAMAC Ghalia",
      street: "",
      area: "JVC",
      district: "JVC Village",
      location: "Dubai",
      pincode: 60035,
      plot_size: null,
      dimensions: "0000",
      facing: "East",
      features: [],
      additional_info: [],
      bedroom: 2,
      bathroom: 2,
      room: 3,
      car_parking: 1,
      garage_size: 1,
      priority: 1,
      configurations: "",
      contract_type: "",
      posted_date: null,
      status: 1,
      propertyStatus: "Ready",
      owner_id: "1838-Ap",
      created_at: "2022-09-17T06:20:33.586Z",
      updated_at: "2022-09-19T10:19:46.009Z",
      basic_needs: [],
      key_distances: [],
      home_area: 2222,
      total_size: 468,
      extra_details: "Haha",
      map_link:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15664.599753156617!2d77.0218907!3d11.027375099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba857f4241245b5%3A0x77bd3d147f7259ff!2sDream%20Snap%20Camera!5e0!3m2!1sen!2sin!4v1663395698327!5m2!1sen!2sin",
      carouselDeletedImages: [],
      floorPlanDeletedImages: [],
      __v: 0,
    },
  ]);

  useEffect(() => {
    dispatch(getPropByFilter("", "", ""));
  }, []);

  useEffect(() => {
    console.log(feature.length, propByFilter.length);
    if (feature.length === propByFilter.length) {
      const script = document.createElement("script");

      script.src = "/assets/js/carousel.js";
      script.async = true;

      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (propByFilter !== undefined) {
      if (propByFilter.length === 0) {
        setLoader(true);
      } else {
        setLoader(false);
        setFeature(propByFilter);
      }
    }
  }, [propByFilter]);

  console.log("feature", feature);

  var $jq = $.noConflict();
  useEffect(() => {
    // What We Carousel
    console.log(feature.length, propByFilter.length);
    if (feature.length === propByFilter.length) {
      const galleryContainer = document.querySelector(".gallery-container");
      console.log(galleryContainer);

      const galleryControlsContainer =
        document.querySelector(".gallery-controls");
      const galleryControls = ["previous", "next"];
      const galleryItems = document.querySelectorAll(".gallery-item");
      class Carousel {
        constructor(e, r, t) {
          this.carouselContainer = e;
          this.carouselControls = t;
          this.carouselArray = [...r];
        }
        updateGallery() {
          this.carouselArray.forEach((e) => {
            e.classList.remove("gallery-item-1");
            e.classList.remove("gallery-item-2");
            e.classList.remove("gallery-item-3");
            e.classList.remove("gallery-item-4");
          });
          this.carouselArray.slice(0, 4).forEach((e, r) => {
            e.classList.add(`gallery-item-${r + 1}`);
          });
        }
        setCurrentState(e) {
          if (e.className == "gallery-controls-previous") {
            this.carouselArray.unshift(this.carouselArray.pop());
          } else {
            this.carouselArray.push(this.carouselArray.shift());
          }
          this.updateGallery();
        }
        setControls() {
          this.carouselControls.forEach((e) => {
            galleryControlsContainer.appendChild(
              document.createElement("button")
            ).className = `gallery-controls-${e}`;
            document.querySelector(`.gallery-controls-${e}`).innerText = e;
          });
        }
        useControls() {
          const e = [...galleryControlsContainer.childNodes];
          e.forEach((e) => {
            e.addEventListener("click", (r) => {
              r.preventDefault();
              if (e.className == "gallery-controls-add") {
                const e = document.createElement("div");
                const r = this.carouselArray.length;
                const t =
                  this.carouselArray.findIndex(
                    (e) =>
                      e.getAttribute("data-index") == this.carouselArray.length
                  ) + 1;
                Object.assign(e, {
                  className: "gallery-item",
                  src: `http://fakeimg.pl/300/?text=${
                    this.carouselArray.length + 1
                  }`,
                });
                e.setAttribute("data-index", this.carouselArray.length + 1);
                this.carouselArray.splice(t, 0, e);
                document.querySelector(`[data-index="${r}"]`).after(e);
                this.updateGallery();
              } else {
                this.setCurrentState(e);
              }
            });
          });
        }
      }

      const exampleCarousel = new Carousel(
        galleryContainer,
        galleryItems,
        galleryControls
      );
      exampleCarousel.setControls();
      exampleCarousel.useControls();

      // scroll chnage the header
      $jq(window).scroll(function () {
        if ($jq(window).scrollTop() >= 80) {
          $jq("nav").addClass("fixed-header");
          $jq("nav div").addClass("visible-title");
        } else {
          $jq("nav").removeClass("fixed-header");
          $jq("nav div").removeClass("visible-title");
        }
      });

      function ScrollDown(event) {
        event.preventDefault();
        $jq("html, body").animate({ scrollTop: "+=650px" }, 800);
      }
      var prevBtn = document.getElementsByClassName(
        "gallery-controls-previous"
      );

      if (prevBtn !== undefined || prevBtn !== null) {
        var a = prevBtn.item(0);
        a.click();
      }
    }
    //Live Chat Code
  }, [propByFilter]);

  // setInterval(() => {
  //   var prevBtn = document.getElementsByClassName("gallery-controls-previous");
  //   if (prevBtn !== undefined || prevBtn !== null) {
  //     console.log('ii')
  //     var a = prevBtn.item(0);
  //     for(var i =1;i<=1;i++) {
  //       console.log(i,'ii')
  //       a.click();
  //     }
   
  //   }
  // }, [9000]);

  return (
    <div>
      {feature ? (
        <section className="project_main_sections">
          <div className="container">
            <div className="row pt-5 pb-3">
              <div className="col-md-12 text-center Welcome_to_Evernest_col">
                <p className="Welcome_to_Evernest">
                  <span
                    style={{
                      color: "#43655A",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Welcome to Beyond Casa Real Estate,
                   
                  </span>
                  &nbsp;Dubai's award-winning and trusted real estate establishment.
                </p>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-md-12 col-12">
                <div className="text-center what_new_div">
                  <h2 className="what_new_heading">
                    Discover What’s Brand New
                  </h2>
                  <p className="what_new_paragraph">
                    Own a home in Dubai Get a property in Dubai with Beyond Casa Real Estate, which offers
                    <br></br>trusted real estate advice for clients. Off-plan
                    deals, Secondary market deals, Rental deals
                  </p>
                </div>
              </div>
            </div>
            <div className="gallery whats_new_gallery">
              <div className="gallery-container">
                {feature &&
                  feature.map((r, index) => (
                    <div
                      style={{
                        backgroundImage: `url('https://beyondcasa.ae:4001/${r.images}')`,
                        border: "3px solid #fff",
                      }}
                      className={`background_image_carouser_${index} gallery-item shadow-lg ps-3 bg-body rounded`}
                      data-index={index}
                    >
                      <a
                        // href="project/sobha-creek-vista-heights.html"
                        href="#"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <div className="same_project_information d-none">
                          <h4 className="project_title_heading">{r.title}</h4>
                          <span style={{ fontSize: "14px" }}>{r.district}</span>
                          <div className="LocDiv mt-3">
                            <span
                              className="d-block fw-normal"
                              style={{ fontSize: "14px" }}
                            >
                              Starting From
                            </span>
                          </div>
                          <div className="StrtPriceDiv">
                            <h5 style={{ fontWeight: "bold", color: "#fff" }}>
                              AED {r.price}
                            </h5>
                          </div>
                          <div className="KnowMoreDiv mt-3">
                            <a
                              className="know_more_btn"
                              // href="project/sobha-creek-vista-heights.html"
                              href={`/product-details/${r._id}`}
                            >
                              know more
                            </a>
                          </div>
                        </div>
                        {/* <!--<div class="OffPlanWhitediv"></div>--> */}
                      </a>
                    </div>
                  ))}
              </div>
              <div className="gallery-controls"></div>
            </div>
            <div className="slider_parents_dot">
              <div
                onclick="useControls()"
                id="getClassfromId"
                className="slider_chiled_dot gallery_item_dot1"
              ></div>
              <div
                onclick="useControls()"
                id="getClassfromId"
                className="slider_chiled_dot gallery_item_dot2"
              ></div>
              <div
                onclick="useControls()"
                id="getClassfromId"
                className="slider_chiled_dot gallery_item_dot3"
              ></div>
              <div
                onclick="useControls()"
                id="getClassfromId"
                className="slider_chiled_dot gallery_item_dot4"
              ></div>
            </div>
            <div className="text-center LoadMore_project_div pt-3">
              <a
                // href="project.html"
                href="/filter-shop?location=&type="
                className="btn btn-default LoadMore_project_btn"
                title="LoadMore"
              >
                LOAD MORE
              </a>
            </div>
          </div>
        </section>
      ) : (
        "Loading.."
      )}
    </div>
  );
};

export default FeaturedItemV1;
