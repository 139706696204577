import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import { getPropById,createBooking } from "../redux/slices/users";
import { getPropById, createBookingAll } from "../../redux/slices/users";
import { height } from "@mui/material/node_modules/@mui/system";
// import { createBooking } from "../../redux/slices/users";

const StatusMsg = () => {
  return (
    <div>
      <div>
        <h3>Sent Successfully</h3>
      </div>
    </div>
  );
};

const ShopDetails = (props) => {
  const dispatch = useDispatch();
  const { booking } = useSelector((state) => state.users);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let domainUrl = localStorage.getItem("ip");
  const [activeTab, setAtiveTab] = useState(0);
  const [createBooking, setCreateBooking] = useState({
    name: "",
    email: "",
    comment: "",
    property_id: "",
    serviceType: "",
    phone: "",
  });

  const icons = [
    {
      name: "Gym",
      icon: "fa-solid fa-dumbbell",
    },
    {
      name: "Media Room",
      icon: "fa-solid fa-music",
    },
    {
      name: "Electricity",
      icon: "fa-solid fa-music",
    },

    {
      name: "Back yard",
      icon: "fa-solid fa-floppy-disk",
    },
    {
      name: "Wash and dryer",
      icon: "fa-solid fa-floppy-disk",
    },
    {
      name: "Garage Attached",
      icon: "fa-solid fa-warehouse",
    },
    {
      name: "Basketball court",
      icon: "fa-solid fa-basketball",
    },
    {
      name: "Hot Bath",
      icon: "fa-solid fa-bath",
    },
    {
      name: "Front yard",
      icon: "fa-solid fa-floppy-disk",
    },
    {
      name: "Pool",
      icon: "fa-solid fa-water-ladder",
    },
    {
      name: "Water",
      icon: "fa-solid fa-water",
    },
    {
      name: "Chair Accessable",
      icon: "fa-solid fa-chair",
    },
    {
      name: "Fireplace",
      icon: "fa-solid fa-fire",
    },
    {
      name: "Wifi",
      icon: "fa-solid fa-wifi",
    },
    {
      name: "Air conditioning",
      icon: "fa-solid fa-square",
    },
    {
      name: "Laundry",
      icon: "fa-solid fa-square",
    },
    {
      name: "Smoke detectors",
      icon: "fa-solid fa-sensor-fire",
    },
    {
      name: "Washer and dryer",
      icon: "fa-solid fa-square",
    },
    {
      name: "Elevator",
      icon: "fa-solid fa-square",
    },
    {
      name: "",
      icon: "fa-solid fa-square",
    },
    {
      name: "Heating",
      icon: "fa-solid fa-square",
    },
    {
      name: "Ventilation",
      icon: "fa-solid fa-square",
    },
    {
      name: "Natural Gas",
      icon: "fa-solid fa-square",
    },
    {
      name: "Central Air",
      icon: "fa-solid fa-square",
    },
  ];

  console.log(booking);

  const { property_id } = createBooking;

  const idDatas = props.propById !== undefined ? props.propById : [];
  const subject =
    props.propById.subject !== undefined ? props.propById.subject : [];
  const basicNeeds =
    props.propById.basic_needs !== undefined ? props.propById.basic_needs : [];
  const key_distances =
    props.propById.key_distances !== undefined
      ? props.propById.key_distances
      : [];
  const amenities =
    props.propById.amenities !== undefined ? props.propById.amenities : [];
  const gallery =
    props.propById.gallery !== undefined ? props.propById.gallery : [];
  const floor_plan =
    props.propById.floor_plan !== undefined ? props.propById.floor_plan : [];
  const video_link =
    props.propById.video_link !== undefined ? props.propById.video_link : [];

  createBooking.property_id = props.propById.code;

  const handleInput = (e) => {
    let { name, value } = e.target;
    setCreateBooking({ ...createBooking, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createBookingAll(
        createBooking.name,
        createBooking.email,
        createBooking.comment,
        createBooking.property_id,
        createBooking.serviceType,
        createBooking.phone
      )
    );
  };
  console.log(createBooking);

  useEffect(() => {
    var setDes = document.getElementById("description");
    if (idDatas) {
      setDes.innerHTML = idDatas.description;
    }
  }, [idDatas]);

  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-category">
                    <Link to="#">{idDatas.contract_type}</Link>
                  </li>

                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />
                    {moment(idDatas.created_at).format("MMMM Do YYYY")}
                  </li>

                  {/* <li>
                      <Link to="#">
                        <i className="far fa-comments" />
                        35 Comments
                      </Link>
                    </li> */}
                </ul>
              </div>
              <h1>{idDatas.title}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {idDatas.location}
              </label>
              {/* <h4 className="title-2">Description</h4>
                <p>{idDatas.description}</p> */}
              <div id="description"></div>
              {/* <p>
                To the left is the modern kitchen with central island, leading
                through to the unique breakfast family room which feature glass
                walls and doors out onto the garden and access to the separate
                utility room.
              </p> */}
              <h4 className="title-2">Property Detail</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                  <li>
                    <label>Property ID:</label> <span>{idDatas.owner_id}</span>
                  </li>
                  <li>
                    <label>Home Area: </label> <span>{idDatas.home_area}</span>
                  </li>
                  <li>
                    <label>Rooms: </label>
                    <span>{idDatas.room}</span>
                  </li>
                  <li>
                    <label>Baths: </label>
                    <span>{idDatas.bathroom}</span>
                  </li>
                  <li>
                    <label>Year built: </label>
                    <span>{idDatas.year_build}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>Total Area:</label>{" "}
                    <span>{idDatas.total_size} </span>
                  </li>
                  <li>
                    <label>Lot Area:</label> <span>{idDatas.lot_area}</span>
                  </li>
                  <li>
                    <label>Beds:</label> <span>{idDatas.bedroom}</span>
                  </li>
                  <li>
                    <label>Price:</label> <span>AED {idDatas.price}</span>
                  </li>
                  <li>
                    <label>Property Status:</label>{" "}
                    <span>{idDatas.contract_type}</span>
                  </li>
                </ul>
              </div>
              {/* <h4 className="title-2">Facts and Basic needs met</h4>
                <div className="property-detail-feature-list clearfix mb-45">
                  <ul>
                    {basicNeeds.map((data) => (
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i
                            class={`${data.icon}`}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                          <div style={{ width: "100px" }}>
                            <h6>{data.name}</h6>
                            <small>{data.size}</small>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <h4 className="title-2">From Our Gallery</h4>
                <div className="ltn__property-details-gallery mb-30">
                  <div className="row">
                    {gallery.map((data, index) => (
                      <div className="col-md-6">
                        <a
                          href={`${domainUrl}/${data}`}
                          data-rel="lightcase:myCollection"
                        >
                          <img
                            className="mb-30"
                            src={`${domainUrl}/${data}`}
                            alt="no"
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div> */}
              <h4 className="title-2 mb-10">Amenities</h4>
              <div className="property-details-amenities mb-60">
                <div className="row">
                  {amenities.map((data, index) => (
                    <div className="col-lg-4 col-md-6">
                      <div className="ltn__menu-widget">
                        <ul>
                          <li>
                            <div className="property-detail-feature-list-item">
                              {icons.map((item) =>
                                item.name === data ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "antiquewhite",
                                        width: "30px",
                                        height: "30px",
                                        marginRight: "12px",
                                      }}
                                    >
                                      <i class={`${item.icon}`} />
                                    </div>

                                    <div style={{ width: "100px" }}>
                                      <label>{item.name}</label>
                                      {/* <small>{data.size}</small> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>

                            {/* <label className="checkbox-item">
                                {data}
                                <input
                                  type="checkbox"
                                  defaultChecked="checked"
                                />
                                <span className="checkmark" />
                              </label> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <h4 className="title-2 mb-10">Key to distance</h4>
              <div className="property-details-amenities mb-60">
                <div
                  className="row"
                  style={{ dispatch: "flex", flexDirection: "column" }}
                >
                  {key_distances.map((data) => (
                    <div className="col-lg-4 col-md-6">
                      <div className="ltn__menu-widget">
                        <ul>
                          <li>
                            <label className="checkbox-item">
                              {data[Object.keys(data)[0]]}
                              <input
                                type="checkbox"
                                //defaultChecked="checked"
                              />
                              <span className="checkmark" />
                            </label>

                            <span> {data[Object.keys(data)[1]]}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <h4 className="title-2">Location</h4>
              <div className="property-details-google-map mb-60">
                <iframe
                  src={idDatas.map_link}
                  width="100%"
                  height="100%"
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
              <h4 className="title-2">Floor Plans</h4>
              {/* APARTMENTS PLAN AREA START */}
              <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                  <div className="nav">
                    {floor_plan.map((data, index) => (
                      <a
                        data-bs-toggle="tab"
                        href={`#liton_tab_3_${index}`}
                        className={`show ${
                          activeTab === index ? "active" : ""
                        }`}
                      >
                        {data.title}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="tab-content">
                  {floor_plan.map((data, index) => (
                    <div
                      className={`tab-pane fade show ${
                        activeTab === index ? "active" : ""
                      }`}
                      id={`liton_tab_3_${index}`}
                      onClick={() => setAtiveTab(index)}
                    >
                      <div className="ltn__apartments-tab-content-inner">
                        <div className="row">
                          <>
                            <div className="col-lg-7">
                              <div className="apartments-plan-img">
                                <a
                                  href={`${domainUrl}/${data.image}`}
                                  data-rel="lightcase:myCollection"
                                >
                                  <img
                                    // className="mb-30"
                                    src={`${domainUrl}/${data.image}`}
                                    alt="#"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>{data.title}</h2>
                                <p>{data.description}</p>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              <div className="widget ltn__form-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Drop Messege For Book
                </h4>
                {booking.status === 1 ? (
                  <div>
                    <StatusMsg />
                  </div>
                ) : (
                  <div>
                    <form action="#">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name*"
                        onChange={handleInput}
                        required
                      />
                      <input
                        type="text"
                        name="phone"
                        placeholder="Your Mobile*"
                        onChange={handleInput}
                        required
                      />
                      <input
                        type="text"
                        name="email"
                        placeholder="Your e-Mail*"
                        onChange={handleInput}
                        required
                      />
                      <textarea
                        name="comment"
                        placeholder="Write Message..."
                        defaultValue={""}
                        onChange={handleInput}
                      />
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn theme-btn-1"
                      >
                        Send Messege
                      </button>
                    </form>
                  </div>
                )}
              </div>
              {/* Top Rated Product Widget */}
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
