import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + '/';
    const minscript = document.createElement('script');
    minscript.async = true;
    minscript.src = publicUrl + 'assets/js/main.js';

    document.body.appendChild(minscript);

    $('.go-top')
      .find('a')
      .on('click', function () {
        $('.quarter-overlay').fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $('.quarter-overlay').fadeOut(300);
        }, 800);
      });

    $(document).on('click', '.theme-btn-1 ', function () {
      $('div').removeClass('modal-backdrop');
      $('div').removeClass('show');
      $('div').removeClass('fade');
      $('body').attr('style', '');
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imgattr = 'Footer logo';

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container">
            <div className="row ">
              <div className="col-xl-4 col-md-6 col-sm-6 col-12 alignFooter">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      {/* <img src={publicUrl+"assets/img/logo-2.png"} alt="Logo" /> */}
                      <h3 style={{ fontWeight: 'bold', color: 'white' }}>
                        Beyondcasa Property
                      </h3>
                    </div>
                  </div>
                  <p>Dubai, UAE</p>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>Dubai</p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="ph:+971 585885334">+971 585885334</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:info@Beyondcasaproperty.com">
                              info@beyondcasa.ae
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6 col-12 alignFooter">
                <div className="footer-widget footer-menu-widget clearfix" style={{textAlign: 'center'}}>
                  <h4 className="footer-title" style={{ marginLeft: '13px' }}>
                    Follow us on
                  </h4>
                  <div className="footer-menu go-top">
                    <div className="ltn__social-media mt-20">
                      <Social />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6 col-12 alignFooter">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Quick links</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      {/* <li>
                        <Link to="/blog-grid">Blog</Link>
                      </li> */}
                      {/* <li>
                        <Link to="filter-shop?type=">
                          All Properties
                        </Link>
                      </li> */}

                      <li>
                        <Link to="/buyResidential">Buy</Link>
                      </li>
                      <li>
                        <Link to="/rentResidential">Rent</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      {/* <li>
                        <Link to="/service">Services</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/faq">FAQ</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/contact">Contact us</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/cart">Cart</Link>
                      </li>
                      <li>
                        <Link to="/wishlist">Wish List</Link>
                      </li>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/checkout">Checkout</Link>
                      </li>
                      <li>
                        <Link to="/about">Terms &amp; Conditions</Link>
                      </li>
                      <li>
                        <Link to="/shop">Promotional Offers</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Customer Care</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/my-account">My account</Link>
                      </li>
                      <li>
                        <Link to="/wishlist">Wish List</Link>
                      </li>
                      <li>
                        <Link to="/add-listing">Add listing</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
