import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Social extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul style={{ fontSize: "20px" }}>
          <li>
            <a href="https://www.facebook.com/beyoncasa/" target="_blank" rel="blank" title="Facebook">
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          {/* <li>
            <a href="#" title="Twitter">
              <i className="fab fa-twitter" />
            </a>
          </li> */}
          <li>
            <a href="https://www.instagram.com/beyondcasa_Beyond Casa Real Estate/" rel="https://www.instagram.com/beyondcasa_dmcc/" target="_blank" title="Instagram">
              <i className="fab fa-instagram" />
            </a>
          </li>
          {/* <li>
            <a href="#" title="Dribbble">
              <i className="fa-brands fa-linkedin" />
            </a>
          </li> */}
          {/* <li>
            <a href="#" title="Youtube">
              <i className="fab fa-youtube" />
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}

export default Social;
