import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./style.css";

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div class="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="Mortgage_Service shadow-lg p-3 rounded">
                <div class="Mortgage_Service_heading">
                  <h1 class="text-white"> Service Maintenance in UAE</h1>
                </div>
                <img
                  class="img-fluid"
                  alt="service-maintenance"
                  src="../images/service-maintenance.jpg"
                />
                <br></br>
                <br></br>

                <p class="are_you_looking">
                  Maintaining a property is crucial for securing good returns on
                  your investment. However, we understand that not all property
                  owners have the time or inclination to handle maintenance
                  tasks due to busy schedules and other priorities. Beyond Casa
                  Real Estate, trusted property maintenance professionals, step
                  in to ensure that your property receives the attention it
                  needs.
                </p>
                <p class="are_you_looking">
                  Our professional team is renowned for their quality
                  workmanship and innovative solutions. We take pride in
                  delivering satisfaction and peace of mind to both property
                  owners and their tenants by diligently recording and
                  completing all necessary maintenance tasks and reports.
                </p>

                {/* <p class="are_you_looking">
                  As your property maintenance company, Evernest Real Estate
                  promises to resolve tenant complaints and concerns after
                  properly investigating the claims, provide routine maintenance
                  checks, inspect the properties before, during, and after
                  tenants reside in the property, and keep detailed records of
                  any maintenance done on the property. By handling all tenant
                  communications, our goals are to improve and maintain the
                  property’s integrity so you can relax and enjoy the maximum
                  return on your investment...Free of Stress!.{' '}
                </p>
                <p class="If_you_require">
                  {' '}
                  We will plan your renovations accordingly and contract the
                  best construction teams in the area to provide quality work.
                  We make suggestions and a variety of recommendations on the
                  best contract services for you to choose from for your home
                  improvement needs{' '}
                </p>

                <ul class="Mortgage_Service_ul">
                  <li> Carpentry</li>
                  <li> Plumbing</li>
                  <li> Electricity</li>
                  <li> Landscaping</li>
                  <li> Paint Refreshing</li>
                  <li> Cleaning</li>
                </ul>
                <p class="If_you_require">
                  The Evernest Real Estate team can handle all of your real
                  estate demands. The expert knowledge shared is invaluable to a
                  successful mortgage experience. We have the essential real
                  estate knowledge that you need to get the best deal.
                </p>
                <p class="If_you_require">
                  At <a href="#">Evernest Real Estate</a>, we want our clients
                  to have the support they need and our tenants to know that we
                  are here to help and manage the property with professional
                  care. We have an exceptionally dedicated team of real estate
                  professionals ready to assist you with your properties. We do
                  all of the hard stuff, so that you can relax and collect the
                  maximum returns on your property and investment. Our thorough
                  customer care provides support for both tenants and property
                  owners. Evernest’s experts understand quality property
                  maintenance and pay close attention to details with our
                  extraordinary property snagging skills.
                </p>
                <p class="If_you_require">100% Satisfaction Guaranteed</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
