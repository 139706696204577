import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { createBookingAll } from "../../redux/slices/users";

const StatusMsg = () => {
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center" }}>Sent Successfully</h3>
      </div>
    </div>
  );
};

const ContactForm = () => {
  const dispatch = useDispatch();
  const { booking } = useSelector((state) => state.users);

  let publicUrl = process.env.PUBLIC_URL + "/";
  const [createBooking, setCreateBooking] = useState({
    name: "",
    email: "",
    comment: "",
    property_id: "",
    serviceType: "",
    phone: "",
  });
  const handleInput = (e) => {
    let { name, value } = e.target;
    setCreateBooking({ ...createBooking, [name]: value });
  };
  const { property_id } = createBooking;
  console.log(createBooking);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createBookingAll(
        createBooking.name,
        createBooking.email,
        createBooking.comment,
        createBooking.property_id,
        createBooking.serviceType,
        createBooking.phone
      )
    );
  };

  return (
    <div className="ltn__contact-message-area mb-60 mb--60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box">
              <h4 className="" style={{textAlign: 'center'}}>Get A Quote</h4>
              <div>
                {booking.status === 1 ? (
                  <StatusMsg />
                ) : (
                  <div>
                    <form id="contact-form-main">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-item input-item-name ltn__custom-icon">
                            <input
                              type="text"
                              placeholder="Enter your name"
                              name="name"
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-item-email ltn__custom-icon">
                            <input
                              type="email"
                              name="email"
                              onChange={handleInput}
                              placeholder="Enter email address"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item">
                            <select
                              className="nice-select"
                              name="serviceType"
                              onChange={handleInput}
                            >
                              <option>Select Service Type</option>
                              <option value="BUY">RENT </option>
                              <option value="SELL">BUY</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-item input-item-phone ltn__custom-icon">
                            <input
                              type="text"
                              name="phone"
                              onChange={handleInput}
                              placeholder="Enter phone number"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-item input-item-textarea ltn__custom-icon">
                        <textarea
                          name="comment"
                          onChange={handleInput}
                          placeholder="Enter message"
                          defaultValue={""}
                        />
                      </div>

                      <div className="btn-wrapper mt-0">
                        <button
                          className="btn theme-btn-1 btn-effect-1 text-uppercase"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <p className="form-messege mb-0 mt-20" />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
