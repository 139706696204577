import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./style.css";

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-12 plr-0">
              <div className="Mortgage_Service shadow-lg p-3 rounded">
                <div className="Mortgage_Service_heading">
                  <h1 className="text-white">Mortgage Service in UAE</h1>
                </div>
                <img
                  className="img-fluid"
                  alt="image-mortgage"
                  src="images/service-mortgage.jpg"
                />
                <br></br>
                <br></br>
                <h4>We Collaborate with Major UAE Banks</h4>
                <p className="are_you_looking">
                  Whether you're planning to purchase a property in Dubai or
                  looking to enhance your property portfolio, seeking guidance
                  from a mortgage expert is a wise decision. At Beyond Casa Real
                  Estate, we're here to provide expert advice on mortgages. We
                  can offer a general estimate of your monthly repayment amount
                  and recommend the best lending bank to suit your needs. Our
                  goal is to streamline your processing charges and secure lower
                  interest rates.
                </p>
                <h4>
                  For Your Real Estate Goals, Our Consultants Ensure a Seamless
                  Mortgage Experience
                </h4>
                <p className="are_you_looking">
                  At Beyond Casa Real Estate, our consultants are dedicated to
                  making your mortgage journey effortless. Our accomplished
                  mortgage advisors provide thorough and genuine consultations
                  on suitable mortgage products. We stand by your side
                  throughout the application process, ensuring a smooth
                  progression at every stage.
                </p>
                <h4>We Maintain Strong Collaborations with Various Banks</h4>
                <p className="are_you_looking">
                  Our enduring partnerships with multiple banks ensure that
                  finances are managed with precision and care. We are proud to
                  offer our services without any concern for ethnicity,
                  background, nationality, financial situation, or age. At
                  Beyond Casa Real Estate, each case is diligently handled to
                  achieve an impressive zero-refusal rate.
                </p>
                <h4>Turning Your Homeownership Dream into Reality</h4>
                <p className="are_you_looking">
                  At Beyond Casa Real Estate, we are always by your side to
                  transform your dream of owning a home into a reality. Our
                  dedicated and highly qualified team, known for vigorous and
                  efficient negotiations, ensures we find the perfect option
                  that aligns with your requirements. Throughout the application
                  process, we provide regular updates, regardless of whether you
                  are a local resident or a foreigner
                </p>
                <h4>
                  Experienced Professionals with Extensive Credit Expertise and
                  Financial Education
                </h4>
                <p className="are_you_looking">
                  Our team at Beyond Casa Real Estate comprises seasoned
                  professionals with significant credit expertise and a strong
                  financial educational background. When it comes to purchasing
                  a property through a mortgage, financial experts consistently
                  recommend obtaining pre-approval. This crucial step allows for
                  a comprehensive analysis of your financial needs and
                  affordability, ensuring a smooth and informed home buying
                  experience.
                </p>

                {/* <p className="are_you_looking">
                  Are you looking for home loans with the best rates?{' '}
                  <a href="#">Evernest Real Estate</a> is here for you! Our
                  talented team of mortgage brokers is ready to help you buy
                  your first property or refinance your existing property loans.
                  We are skilled in finding the best mortgage rates and offers.
                  Let Evernest’s experience in property finance take care of
                  your best interests. Evernest Real Estate’s Property Finance
                  experts will work to find the best deals for you. Experienced
                  mortgage advisors are prepared to do what it takes to offer
                  you competitive rates that are low and tailored specifically
                  to your needs. Whether you are interested in financing a new
                  or an existing property, Evernest experts are here to provide
                  you with the best rates from the best banks in the UAE.{' '}
                </p>
                <p className="If_you_require">
                  If you require assistance with your current mortgage rates or
                  any of the following, please contact us today!{' '}
                </p>
                <ul className="Mortgage_Service_ul">
                  <li> Portfolio Structures</li>
                  <li> Private Equity</li>
                  <li> Land Acquisition</li>
                  <li> Construction Projects</li>
                  <li> Commercial Loans</li>
                  <li> Any other mortgage services</li>
                </ul>
                <p className="If_you_require">
                  {' '}
                  <a href="#">Our team</a> can handle all of your real estate
                  demands. The expert knowledge shared is invaluable to a
                  successful mortgage experience. We have the essential real
                  estate knowledge that you need to get the best deal.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
