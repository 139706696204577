import React, { useEffect , useState } from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import ProductSlider from "./shop-components/product-slider-v1";
import ProductDetails from "./shop-components/shop-details";
import SliderTesting from "./shop-components/slider-testing";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropById } from "../redux/slices/users";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

const imgs = [
  "https://picsum.photos/1904/1006",
  "https://picsum.photos/1904/1006",
  "https://picsum.photos/1904/1006",
];

const Product_Details = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { propById } = useSelector((state) => state.users);
  var carousel_images = [];
  var gallery_images = [];
  const [loader , setLoader] = useState(true);

  useEffect(() => {
    dispatch(getPropById(id));
  }, []);

  useEffect(() =>{
    console.log(carousel_images,'carousel_imagescarousel_images')
    if (carousel_images.length === 0) {
      setLoader(true);
    } else {
      setLoader(false);
     }
  },[carousel_images])

  if (propById.carousel_images !== [] && propById.gallery !== []) {
    if (propById.carousel_images !== undefined && propById.gallery !== undefined) {
      carousel_images = propById.carousel_images;
      gallery_images = propById.gallery;
      
    } else {
      carousel_images = [];
      gallery_images = [];
    }
  } else {
    carousel_images = [];
    gallery_images = [];
  }


  // if (propById.gallery !== []) {
  //   if (propById.gallery !== undefined) {
  //     gallery_images = propById.gallery;
  //   } else {
  //     gallery_images = [];
  //   }
  // } else {
  //   gallery_images = [];
  // }


  console.log(propById);

  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Product Details" customclass="mb-0" />
      {/* <ProductSlider carouselImages={carousel_images} /> */}
      {loader ? <Box sx={{ display: 'flex' }}>
        <div  style={{margin: 'auto', marginTop: '60px', marginBottom: '60px'}}>
        <CircularProgress/>
        <Typography>Loading...</Typography>
          </div>
     
    </Box> : <>
      <SliderTesting carouselImages={carousel_images} galleryImages={gallery_images}/>
      <ProductDetails propById={propById} Id={id} />
      <CallToActionV1 />
      </>}
      <Footer />
  
    </div>
  );
};

export default Product_Details;
