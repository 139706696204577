import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { transform } from "lodash";

class VideoV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__slider-area ltn__slider-4 position-relative">
        {/* <div
          style={{
            position: "absolute",
            bottom: 0,
            color: "white",
            transform: 'translate(50%, -50%)',
          }}
        >
          Real Estate Agency
        </div> */}
        <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active" style={{textAlign: 'center'}}>
          {/* HTML5 VIDEO */}
          <video autoPlay muted loop playsinline id="myVideo">
            <source src={publicUrl + "assets/media/3.mp4"} type="video/mp4" />
          </video>
         
        </div>
      </div>
    );
  }
}

export default VideoV4;
