import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.css';

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div class="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                 
                </div>{' '}
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="Mortgage_Service shadow-lg p-3 rounded">
                <div class="Mortgage_Service_heading">
                  <h1 class="text-white">Property Snagging Service in UAE</h1>
                </div>
                <img
                  class="img-fluid"
                  alt="Property-Snagging-image"
                  src="../images/service-snagging.jpg"
                />
                <br></br>
                <br></br>

                <p class="are_you_looking">
                  As the moment to finally receive the keys to your dream home approaches, it's crucial to conduct a pre-check of the property before the handover. At Beyond Casa Real Estate, we understand this importance and offer comprehensive handover and snagging services on your behalf.
                </p>

                <p>Our team ensures a fast and efficient representation during handover meetings, alleviating any stress associated with the procedure. This is particularly valuable for our clients who reside overseas and are unable to attend the handover and snagging in person.</p>
                <h4>Our Property Handover/Snagging Service Includes:</h4>

                <ul class="Mortgage_Service_ul">
                  <li>
                  Thorough Inspection: We conduct a comprehensive snagging process to ensure everything is in perfect and suitable condition.
                  </li>
                  <li>
                  Document Preparation: We assist with the preparation of essential documents, including utility connections, pre-checks, and proof of handover.
                  </li>
                  <li>
                  Documentation Package: You'll receive a complete documentation package, including handover acknowledgments, manuals, appliance warranties, rules and regulations, and the handover of keys and access cards.
                  </li>
                </ul>

                {/* <p class="are_you_looking">
                  Many property owners get caught up in property exchanges on
                  property snagging. We guarantee that you receive your property
                  in perfect condition. Evernest Real Estate’s Property Snagging
                  Services are top-notch. Our{' '}
                  <a href="#">real estate experts</a> know what snags and flaws
                  to look for and can effectively communicate with developers to
                  be sure your property is in the best possible condition before
                  you get your keys and assume full responsibility for the
                  property!.
                </p>

                <p class="are_you_looking">
                  Many property owners get caught up in property exchanges on
                  property snagging. We guarantee that you receive your property
                  in perfect condition. Evernest Real Estate’s Property Snagging
                  Services are top-notch. Our real estate experts know what
                  snags and flaws to look for and can effectively communicate
                  with developers to be sure your property is in the best
                  possible condition before you get your keys and assume full
                  responsibility for the property!.
                </p>

                <p class="If_you_require">
                  If you require assistance with your current mortgage rates or
                  any of the following, please contact us today!{' '}
                </p>

                <p class="If_you_require">
                  We know what to be on the lookout for when it comes to
                  property snagging. Our expert team pays attention to the
                  little details that often go unnoticed until it is too late.
                  The audits, claims, investigations, and inspections we provide
                  are thorough and leave very little to be overlooked. In
                  addition, we are available for any questions you may have at
                  any time, day or night. 24/7.
                </p>
                <p class="If_you_require">
                  The expert care you receive when working with Evernest Real
                  Estate’s professional team is beyond exceptional and worth the
                  investment to ensure your property needs are managed
                  satisfactorily and appropriately.{' '}
                </p>

                <p class="If_you_require">
                  Evernest Property Snagging Services can include, but are not
                  limited to...
                </p> */}

                {/* <ul class="Mortgage_Service_ul">
                  <li> Building Handover Inspections</li>
                  <li> Fire/Smoke Damage </li>
                  <li> Warranty Inspections</li>
                  <li> Building Audits </li>
                  <li> Pipeline Inspections</li>
                  <li> Energy Audits</li>
                  <li> Disability Access</li>
                  <li> Maintenance Audits</li>
                  <li> Resale Inspections </li>
                  <li> Insurance Claims</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
