import React from 'react';
// import Navbar from './global-components/navbar';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar-filterd2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Shop_V1 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type");
  const sub_type = urlParams.get("subType");

  let HeaderTitle =
    type === "2" ? "Rent" : type === "1" ? "Buy" : type === "" ? "All" : "";

  return (
    // But commercial
    <div>
      <Navbar />
      <PageHeader headertitle={HeaderTitle} />
      <ShogGrid />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Shop_V1;
