import React, { Component, useEffect, useState } from "react";
import Radium from "radium";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getUserList, getPropByFilter } from "../../redux/slices/users";
import Slider from "react-slick";
import "./featuredClass.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
const style = {
  color: "#000000",
  ":hover": {
    color: "#ffffff",
  },
};

const hr = {
  borderTop: "1px dashed red",
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="customClassNext" style={{ style }} onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="customClassPrev" style={{ style }} onClick={onClick} />
  );
}

const FeaturedItemV1 = () => {
  const [loader, setLoader] = useState(true);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let domainUrl = localStorage.getItem("ip");
  const dispatch = useDispatch();
  const { propByFilter } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(getPropByFilter("", "", ""));
  }, []);

  const divData = [];

  // propByFilter.map(() => {
  //   divData.push();
  // });

  useEffect(() => {
    if (propByFilter !== undefined) {
      if (propByFilter.length === 0) {
        setLoader(true);
      } else {
        setLoader(false);
      }
    }
  }, [propByFilter]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // console.log(propByFilter);
  return (
    <div>
      {loader ? (
        <Box sx={{ display: "flex" }}>
          <div
            style={{ margin: "auto", marginTop: "60px", marginBottom: "60px" }}
          >
            <CircularProgress />
            <Typography>Loading...</Typography>
          </div>
        </Box>
      ) : (
        <div
          className="ltn__product-area ltn__product-gutter"
          // style={{ marginTop: '90px' }}
        >
          <div className="container">
            <div className="row">
              <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title-area ltn__section-title-2--- text-center">
                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                          Properties
                        </h6>
                        <h1 className="section-title">Featured Listings</h1>
                      </div>
                    </div>
                  </div>

                  {/* ltn__product-item */}

                  <div className="tab-content">
                    <div className="tab-pane active" id="liton_product_grid">
                      <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                        <div className="row">
                          {/* <div className="col-lg-12"> */}
                          {/* <Slider {...settings}> */}
                          {propByFilter.map((data, index) => {
                            var show3 = [];
                            if (index <= 2) {
                              show3.push(data);
                            }
                            return show3.map((data) => (
                              <div className="col-xl-4 col-sm-6 col-12">
                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center--- select-item">
                                  <div className="product-img go-top">
                                    <Link to={`/product-details/${data._id}`}>
                                      <img
                                        src={`${domainUrl}/${data.images}`}
                                        alt="#"
                                      />
                                    </Link>
                                    <span className="properties__ribon">
                                      {data.contract_type}
                                    </span>
                                    <div className="product-badge"></div>
                                    <div className="product-img-location-gallery"></div>
                                  </div>
                                  <div className="product-info">
                                    <p className="product-item">{data.title}</p>
                                    <h3 className="product-address">
                                      {data.address}
                                    </h3>

                                    <hr className="divider" />
                                    <ul className="ltn__list-item-2 ltn__list-item-2-before">
                                      <li>
                                        <span>
                                          {data.bedroom} <i className="flaticon-bed" />
                                        </span>
                                        Bedrooms
                                      </li>
                                      <li>
                                        <span>
                                          {data.bathroom} <i className="flaticon-clean" />
                                        </span>
                                        Bathrooms
                                      </li>
                                      <li>
                                        <span>
                                         {data.total_size}
                                          <i className="flaticon-square-shape-design-interface-tool-symbol" />
                                        </span>
                                        square Ft
                                      </li>
                                    </ul>
                                    <div className="item-price">
                                      <strong className="item-price-amount">
                                        AED {data.price}
                                      </strong>
                                      {/* <span style={{ direction: "ltr" }}>
                                        / {data.price}
                                      </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ));
                          })}
                          <div className="text-center LoadMore_project_div pt-3">
                            <a
                              // href="project.html"
                              href="/filter-shop?location=&type="
                              className="btn btn-default LoadMore_project_btn"
                              title="LoadMore"
                            >
                              LOAD MORE
                            </a>
                          </div>
                          {/* </Slider> */}
                          {/* </div> */}

                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedItemV1;
