import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/13.png"}
                  alt="About Us"
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6
                    className="section-subtitle section-subtitle-2 ltn__secondary-color"
                    style={{ marginTop: "20px" }}
                  >
                    About Us
                  </h6>
                  <h1 className="section-title">
                    About Beyond Casa Real Estate Real Estate<span>.</span>
                  </h1>
                  <p>
                    "Beyond Casa Real Estate LLC is a highly reputable
                    organization operating in the dynamic real estate market of
                    the UAE, particularly in Dubai. Our primary goal is to
                    establish trust and offer a secure platform for real estate
                    investment in Dubai, with a steadfast commitment to
                    prioritizing our clients' needs. We are readily available to
                    address all customer inquiries, and our team of
                    professionals provides swift and comprehensive assistance,
                    offering a diverse range of options to cater to our clients'
                    real estate service needs."
                  </p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    YOU FIRST
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    COMPETENCY
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    EXPERIENCE
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    CLIENTS LOVE US
                  </li>
                </ul>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "We are proficient in addressing all inquiries and providing
                    professional assistance to clients, offering a broad
                    spectrum of support for corporate, business, and individual
                    real estate services."
                  </p>
                </div>
                {/* <div className="btn-wrapper animated">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row order-chenge mt-5">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-title">
                    Our Mission<span>:</span>
                  </h1>
                  <h1>Be R.E.A.L</h1>
                  <p>R. Realtors with passion for success and growth.</p>
                  <p>E. Easing customer’s journey towards a happy future.</p>
                  <p>A. Absolute commitment towards customer satisfaction.</p>
                  <p>L. loyal to our internal and external customers.</p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Smart Home Design
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Beautiful Scene Around
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    Exceptional Lifestyle
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    Complete 24/7 Security
                  </li>
                </ul>
                {/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "Enimad minim veniam quis nostrud exercitation <br />
                    llamco laboris. Lorem ipsum dolor sit amet"{" "}
                  </p>
                </div> */}
                {/* <div className="btn-wrapper animated">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/17.png"}
                  alt="About Us"
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/new.jpg"}
                  alt="About Us"
                />
              </div>
            </div>

            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-title">
                    Our Vision<span>:</span>
                  </h1>
                  <p>
                    "To be the preferred real estate company for both employees and customers."
                  </p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Smart Home Design
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Beautiful Scene Around
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    Exceptional Lifestyle
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    Complete 24/7 Security
                  </li>
                </ul>
                {/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "Enimad minim veniam quis nostrud exercitation <br />
                    llamco laboris. Lorem ipsum dolor sit amet"{" "}
                  </p>
                </div> */}
                {/* <div className="btn-wrapper animated">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row order-chenge">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-title">Our Goals</h1>

                  {/* <h1 className="section-title">
                    About Beyond Casa DMCC Real Estate<span>.</span>
                  </h1> */}
                  <p>1.	Establish strong relationships with our clients.</p>
                  <p>2.	Enhance the lives of the clients we serve.</p>
                  <p>3.	Uphold our values of accountability and integrity.</p>
                </div>
                {/* <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    YOU FIRST
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    COMPETENCY
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    EXPERIENCE
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    CLIENTS LOVE US
                  </li>
                </ul> */}
                {/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "We are competent to address all queries and professionally
                    assist clients with a wide range of support to cooperates,
                    business and individual’s real estate services."
                  </p>
                </div> */}
                {/* <div className="btn-wrapper animated">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/14.png"}
                  alt="About Us"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV4;
