import React from 'react';
// import Navbar from './global-components/navbar';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import PropertyMortgageDetails from './section-components/propertyMortgageDetails';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const PropertyMortgage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const sub_type = urlParams.get('subType');

  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Service" />
      <PropertyMortgageDetails />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default PropertyMortgage;
