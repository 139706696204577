import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./style.css";

class PropertyMortgage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div class="row">
            <div className="col-lg-3 col-md-3 col-12 serviceCol plr-0">
              <div className="servicenav_div shadow-lg rounded">
                <div
                  id="description"
                  className="property-description detail-block target-block"
                >
                  <h3 className="services">Services</h3>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyRentSale">Property Rent / Sale</a>
                  </div>
                  
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyManagement">Property Management</a>
                  </div>
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/serviceMaintenance">Service Maintenance</a>
                  </div>

                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertyMortgage">Property Mortgages</a>
                  </div>
                  {/* <div
                    className="services_div"
                    style={{
                      borderBottom: '1px dotted #cccccc',
                      paddingLeft: '5px',
                      padding: '10px'
                    }}
                  >
                    <a href="/propertyRegistration">Property Registration</a>
                  </div> */}
                  <div
                    className="services_div"
                    style={{
                      borderBottom: "1px dotted #cccccc",
                      paddingLeft: "5px",
                      padding: "10px",
                    }}
                  >
                    <a href="/propertySnagging">Property Snagging</a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="Mortgage_Service shadow-lg p-3 rounded">
                <div class="Mortgage_Service_heading">
                  <h1 class="text-white">Property Management Service in UAE</h1>
                </div>
                <img
                  class="img-fluid"
                  alt="properties-service-management"
                  src="../images/service-management.jpg"
                />
                <br></br>
                <br></br>

                <p class="are_you_looking">
                  In Dubai's rapidly expanding real estate market, the goal is
                  to invest in one of the safest places in the world and own
                  property here. At Beyond Casa Real Estate, we're here to take
                  care of various property-related issues, from fixing
                  disintegrated plumbing, electrical, and air conditioning
                  systems to addressing inoperative dishwashers and washing
                  machines. Our property management team is not only
                  well-educated but also well-equipped to handle any equipment
                  or machinery issues.
                </p>
                <p class="are_you_looking">
                  Our RERA-certified team is dedicated to safeguarding and
                  preserving your investment, saving you valuable time. We
                  understand the importance of your investment and are committed
                  to ensuring continuous returns and consistent income
                </p>
                <p class="are_you_looking">Administration and Paperwork</p>
                <p class="are_you_looking">
                  Administration and paperwork are made hassle-free by our
                  professional and experienced property managers who possess
                  in-depth knowledge of local laws and regulations. We handle
                  all aspects of analysis, survey reports, liaisons, and
                  financial procedures with the utmost professionalism,
                  providing you with peace of mind you can rely on.
                </p>

                {/* <p class="are_you_looking">
                  Managing properties can be a challenge and often
                  time-consuming. Evernest Property Management allows you to be
                  free from the daily stresses of the real estate world. You can
                  live your life in peace, knowing that we are managing your
                  property and tenants with expert care and consideration. With
                  Evernest’s experienced team, you can relax and enjoy the finer
                  things in life! Leave the tough stuff to us! Evernest’s
                  experts will take the time to screen new tenants, manage
                  current tenants, collect payments, and handle any maintenance
                  needed. We assure you that every aspect of your property
                  management needs will be met in a genuinely attentive manner.
                  We understand those tenant relations are an important aspect
                  that requires certain consideration and proficiency that our
                  team most certainly possesses.{' '}
                </p>

                <p class="are_you_looking">
                  Our Property Management Services include interior design,
                  building/facilities management, snagging services, home
                  renovations, maintenance, and check collection. Utilizing our
                  Property Management Services ensures a better occupancy rate,
                  quality home improvements, and consistent maintenance. You can
                  trust us with your property management needs so you can relax
                  and reap the rewards of being a property owner.{' '}
                  <a href="#">Our attentive team</a> guarantees quality
                  management and efficient response times for tenants. Evernest
                  Real Estate supports property owners and tenants in every
                  aspect of their real estate demands.{' '}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyMortgage;
